import React from 'react';
import Activity, { newActivity, activityEquals, activityIsEmpty, activityFromJson } from '../../modules/data-objects/activity';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Subject from '../../modules/data-objects/subject';
import { getSysInfo } from '../../modules/data-objects/sys-info';
import { showConfirm } from '../../modules/show-alert';
import cupidFetch from '../../modules/cupid-fetch';
import EventSessionCode from './event-session-code';
import AttachmentLists from './attachment-lists';
import FileUpload from './file-upload';
import MainInputFields from './main-input-fields';
import { LoggedInAs } from '../utils/logged-in-as';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { iheemWebSiteUrl } from '../app/app';

class Props {
    activityId?: string | null;
    returnUrl?: string | null;
    subject!: Subject;
}

class State {
    activity: Activity;
    lastSavedActivity: Activity;
    waitingForServer: boolean = false;
    uploadInProgress: boolean = false;
    lastAutoSaveFailed: boolean = false;
    anyAttachments: boolean | null = null;
    attachmentListNonce: number = 0;
    eventSessionCodeDirty: boolean = false;
    isCompletedOverride: boolean = false;
    constructor(activityId: string | null, subject: Subject) {
        this.activity = newActivity(activityId);
        this.activity.isEventSession = subject.isSysadmin()
        this.lastSavedActivity = { ...this.activity };
    }
}

class _EditCpd extends React.Component<Props & RouteComponentProps, State> {
    state: State = new State(this.props.activityId ?? null, this.props.subject);
    autoSaveTimer: NodeJS.Timeout | null = null;
    firstSavePromise: Promise<Activity | null> | null = null;
    mounted: boolean = false

    isNew = () => !(this.state.activity.activityId);

    isDirty = (ignoreIsCompleted: boolean) =>
        this.isNew() || activityEquals(this.state.activity, this.state.lastSavedActivity, ignoreIsCompleted);

    setAutoSaveTimer() {
        const interval = getSysInfo().clientAppConfig.autoSaveInterval;
        if (!interval) {
            throw new Error(`autoSaveInterval is ${interval}`);
        }
        this.autoSaveTimer = setInterval(() => this.saveActivity(true), interval);
    }

    goBack() {
        this.props.history.push(this.props.returnUrl ?? "/");
    }

    async componentDidMount() {
        this.mounted = true
        if (this.isNew()) {
            this.setAutoSaveTimer();
        } else {
            await this.fetchActivity();
        }
    }

    componentWillUnmount() {
        this.mounted = false
        if (this.autoSaveTimer) {
            clearInterval(this.autoSaveTimer);
            this.autoSaveTimer = null;
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        /* 
         * if the user is editing an activity which was completed, we'll set it back to draft when it becomes
         * dirty (and back to completed if it becomes clean again). However, once the user has clicked one
         * of the radio buttons (i.e. isCompletedOverride = true) we stop doing that.
         * Event sessions don't have the same rule because we can't set them back to draft in case
         * they are referenced.
         */
        if (!this.state.activity.isEventSession && !this.state.isCompletedOverride) {
            const isCompleted: boolean = !this.isDirty(true) && this.state.lastSavedActivity.isCompleted;
            if (isCompleted !== this.state.activity.isCompleted) {
                this.updateFields({ isCompleted });
            }
        }
    }

    updateFields = (updates: Partial<Activity>) => {
        this.setState(state => ({ activity: { ...state.activity, ...updates } }));
    }

    isCompletedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isCompleted = event.target.value === "true";
        this.updateFields({ isCompleted });
        this.setState({ isCompletedOverride: true });
    }

    eventSessionSelected = (selectedEventSession: Activity | null) => {
        /*
        * There's 3 scenarios for this callback:
        * 1. user has just verified an event session code => copy values from the event session
        * 2. user has just cleared the event session code => blank out the values
        * 3. we're editing an event session => just use the event session code directly
        */
        const eventSession = selectedEventSession ?? newActivity(null);
        const extraUpdates: Partial<Activity> = this.state.activity.isEventSession
            ? {}
            : {
                activityDate: eventSession.activityDate,
                title: eventSession.title,
                activityTypeId: eventSession.activityTypeId,
                points: eventSession.points,
                hours: eventSession.hours,
            }
        this.updateFields({ eventSessionCode: eventSession.eventSessionCode, ...extraUpdates })
        this.setState({ eventSessionCodeDirty: false })
    }

    eventSessionCodeDirtyCallback = (dirty: boolean): void => this.setState({ eventSessionCodeDirty: dirty });

    // doSave returns true if the save worked or if there was no need to save
    fileUploadSaveActivityCallback = async (): Promise<boolean> => this.saveActivity(false);

    uploadInProgressCallback = async (inProgress: boolean) => {
        this.setState({ uploadInProgress: inProgress });
        if (!inProgress) {
            // an upload completed or failed, so let's refresh the attachments
            this.setState(state => ({ attachmentListNonce: state.attachmentListNonce + 1 }));
        }
    }

    anyAttachmentsCallback = (anyAttachments: boolean) => this.setState({ anyAttachments });

    onCloseClick = async () => {
        if (this.state.eventSessionCodeDirty) {
            if (!await (showConfirm("You haven't verified changes to the session code. do you want to save without it?"))) {
                return;
            }
        }
        if (this.isDirty(false) && this.state.lastAutoSaveFailed) {
            if (await (showConfirm("Activity has not been saved, do you want to exit without saving?"))) {
                this.goBack();
                return;
            }
        }
        this.setState({ waitingForServer: true });
        const saveOk = await this.saveActivity(false);
        this.setState({ waitingForServer: false });
        if (saveOk) {
            this.goBack();
        }
    }

    onDeleteClick = async () => {
        // note: anyAttachments will be null if the attachments list hasn't loaded yet. we play safe
        // and still show the message in this case.
        if (!activityIsEmpty(this.state.activity) || this.state.anyAttachments !== false) {
            if (!await showConfirm("Are you sure you want to delete this CPD record?")) {
                return;
            }
        }
        this.setState({ waitingForServer: true });
        let navigatedAway: boolean = false;
        try {
            // Step 1: if we're in the middle of creating the activity on the server, we need to
            // wait until we know whether that worked or not
            let activityId: string | null;
            if (this.firstSavePromise) {
                const result = await this.firstSavePromise;
                if (!result) {
                    return;
                }
                activityId = result.activityId;
            } else {
                activityId = this.state.activity.activityId;
            }
            // Step 2: delete the activity if it exists
            if (activityId !== null) {
                const result = await cupidFetch(`/api/activities/${activityId}`, "DELETE");
                if (result == null) {
                    // delete failed so don't leave the screen
                    return;
                }
            }
            navigatedAway = true;
            this.goBack();
        } finally {
            if (!navigatedAway) {
                this.setState({ waitingForServer: false });
            }
        }
    }

    async fetchActivity() {
        this.setState({ waitingForServer: true });
        const result = await cupidFetch<null, Activity>(`/api/activities/${this.state.activity.activityId}`);
        if (result) {
            if (!this.mounted) return
            const activity = activityFromJson(result);
            this.setState(state => ({
                activity,
                lastSavedActivity: { ...activity },
                waitingForServer: false,
            }));
            this.setAutoSaveTimer();
        }
    }

    // returns true if the save worked or if there was no need to save
    async saveActivity(isAutoSave: boolean): Promise<boolean> {
        if (!this.isDirty(isAutoSave)) {
            return true;
        }
        const method = this.isNew() ? "POST" : "PATCH";
        const urlSuffix = this.isNew() ? "" : `/${this.state.activity.activityId}`;
        // normal activities are always auto-saved as draft, but completed event sessions are 
        // auto-saved as completed because they might be referenced by activities
        const activityToSave = {
            ...this.state.activity,
            isCompleted: isAutoSave && !this.state.activity.isEventSession ? false : this.state.activity.isCompleted
        }
        const promise = cupidFetch<Activity, Activity>(`/api/activities${urlSuffix}`, method, activityToSave);
        if (this.isNew()) {
            this.firstSavePromise = promise;
        }
        const result = await promise;
        this.firstSavePromise = null;
        if (result) {
            const activity = activityFromJson(result);
            this.setState({ lastSavedActivity: activity });
            if (this.isNew()) {
                this.setState(state => ({
                    activity: { ...state.activity, activityId: activity.activityId, userId: activity.userId }
                }
                ));
                const returnUrl = encodeURIComponent(this.props.returnUrl ?? "/")
                this.props.history.push(`/activities/${activity.activityId}/edit?returnurl=${returnUrl}`)
            }
        }
        if (isAutoSave) {
            this.setState({ lastAutoSaveFailed: !result });
        }
        return !!result;
    }

    render() {
        const fieldsDisabled = this.state.waitingForServer;
        const buttonsDisabled = this.state.uploadInProgress || this.state.waitingForServer;
        const competencyRefLink = `${iheemWebSiteUrl}/registration/#competances_downloads`
        const sessionCodeLink = `${iheemWebSiteUrl}/learning-hub/library/iheem-mycpd-system-session-codes/`

        return (
            <div className="grid-container main-content">
                <div className="grid-x">
                    <div className="cell medium-12">
                        <h1 className="page-title">Edit CPD</h1>
                        <LoggedInAs subject={this.props.subject} />
                    </div>
                </div>
                <div className="grid-x">
                    <div className="cell medium-12 intro-text">
                        <h3>Helpful Hints:</h3>
                        <p>Session code – for preloaded IHEEM CPD activity you attended, enter the event session code and click Verify. This will prepopulate all standard fields and backup resources.
                        For a list of all session codes see
                            <span> </span>
                            <a href={sessionCodeLink} target="_blank" rel="noopener noreferrer">{sessionCodeLink}</a>.
                            </p>
                        <p>For non session code CPD, enter the relevant data from Title onwards.</p>
                        <p>Competency reference – for you to reference the Engineering Council competency if required.
                            For details of the relevant competency codes based on your registration type see
                            <span> </span>
                            <a href={competencyRefLink} target="_blank" rel="noopener noreferrer">{competencyRefLink}</a>.
                            If you want to put more than one reference against a single activity, enter using a comma and space between each one (Eg. 1A, 2C).
                        </p>
                        <p>You must complete the following four questions to be able to complete your piece of CPD. You can also upload multiple file attachments.</p>
                    </div>
                </div>
                <div className="grid-x">
                    <div className="cell medium-7">
                        <div className="callout secondary form-box">
                            <fieldset disabled={fieldsDisabled}>
                                <div className="grid-x grid-padding-x">
                                    <EventSessionCode eventSessionMode={this.state.activity.isEventSession}
                                        activityId={this.state.activity.activityId}
                                        dirtyCallback={this.eventSessionCodeDirtyCallback}
                                        initialEventSessionCode={this.state.activity.eventSessionCode}
                                        eventSessionSelected={this.eventSessionSelected}
                                    />
                                    <MainInputFields activity={this.state.activity} updateFieldCallback={this.updateFields} />
                                    <FileUpload
                                        activityId={this.state.activity.activityId}
                                        uploadInProgressCallback={this.uploadInProgressCallback}
                                        saveActivityCallback={this.fileUploadSaveActivityCallback} />
                                    <div className="large-5 cell">
                                        <label>Status</label>
                                        <input type="radio" name="isCompleted" id="rdo_notCompleted" value="false"
                                            checked={!this.state.activity.isCompleted}
                                            onChange={this.isCompletedChange} />
                                        <label htmlFor="rdo_notCompleted">Draft</label>
                                        <input type="radio" name="isCompleted" id="rdo_isCompleted" value="true"
                                            checked={this.state.activity.isCompleted}
                                            onChange={this.isCompletedChange} />
                                        <label htmlFor="rdo_isCompleted">Completed</label>
                                    </div>
                                    <div className="small-12 cell">
                                        <div className="small button-group float-right">
                                            <button disabled={buttonsDisabled} className="alert button" onClick={this.onDeleteClick}>
                                                <FontAwesomeIcon icon={faTrash} />
                                                <span> Delete</span>
                                            </button>
                                            <button disabled={buttonsDisabled} className="success button" onClick={this.onCloseClick}>
                                                <FontAwesomeIcon icon={faCheckCircle} />
                                                <span> Save</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div className="cell medium-4 medium-offset-1">
                        <AttachmentLists
                            activity={this.state.activity}
                            attachmentListNonce={this.state.attachmentListNonce}
                            anyAttachmentsCallback={this.anyAttachmentsCallback}
                            enableDelete={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const EditCpd = withRouter(_EditCpd);
export default EditCpd;
