import * as H from 'history';

export const unicode_no_break_space = "\u00a0";
export const unicode_copyright_sign = "\u00a9";
export const unicode_em_space = "\u2003";

export const text_expected_learning = "What did you expect to learn?";
export const text_actual_learning = "What did you learn?";
export const text_impact = "How do you see this impacting your work (next steps)?";
export const text_comments = "Any other comments?";

export function nanToNull(numericValue: number) {
    return isNaN(numericValue) ? null : numericValue;
}

export function encodeCurrentUrl(history: H.History<unknown>) {
    return encodeURIComponent(history.location.pathname + history.location.search);
}

// returns s unless s is s1, in which case it returns s2
// (a bit like null coalescing but with s1 instead of null)
export const substituteString = (s: string, s1: string, s2: string) => s === s1 ? s2 : s

export class BreadcrumbSegment {
    name!: string
    path!: string
}

/*
 * getBreadcrumbs splits the path into segments, each having a name and sub-path.
 * e.g. "/a/b" => [{ name: "a", path: "/a/" }, { name: "b", path: "/a/b/" }].
 * Leading and trailing slashes on the incoming path are ignored, as are double slashes.
 * returned paths have both leading and trailing slashes.
 * If the incoming path has only slashes, the output array is empty.
 */
export function getBreadcrumbs(s: string): BreadcrumbSegment[] {
    const segments = s.split("/").filter(s => s)
    return segments.map((s, i) => {
        return {
            name: s,
            path: segments.slice(0, i + 1).reduce((acc, cur) => acc + cur + "/", "/")
        }
    })
}
