import React, { useState, useEffect } from 'react'
import Subject from '../../modules/data-objects/subject'
import TargetProgress from '../../modules/data-objects/target-progress'
import cupidFetch from '../../modules/cupid-fetch'
import getCurrentDate from '../../modules/current-date'
import ProgressGauge, { gaugeWouldRender } from './progress-gauge'

class Props {
    subject!: Subject
    year?: number
    userId?: string
}

export default function ProgressWidget({ subject, year, userId }: Props) {
    const [targetPoints, setTargetPoints] = useState<number | null>(null)
    const [targetHours, setTargetHours] = useState<number | null>(null)
    const [actualPoints, setActualPoints] = useState(0)
    const [actualHours, setActualHours] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const selectedYear = year ?? getCurrentDate().getFullYear()

    useEffect(
        () => {
            let isMounted = true;
            (async () => {
                const userSuffix = userId ? `?userid=${userId}` : ""
                const result = await cupidFetch<null, TargetProgress>(`/api/activities/targetprogress/${selectedYear}${userSuffix}`)
                if (result && isMounted) {
                    setTargetPoints(result.targetPoints)
                    setTargetHours(result.targetHours)
                    setActualPoints(result.actualPoints)
                    setActualHours(result.actualHours)
                    setLoaded(true)
                }
            })()
            return () => { isMounted = false }
        },
        [selectedYear, userId]
    )

    return (
        <>
            {!subject.isSysadmin() && (gaugeWouldRender(targetPoints, actualPoints) || gaugeWouldRender(targetHours, actualHours)) &&
                <div className="medium-float-right meter">
                    <ProgressGauge title="Points" target={targetPoints} actual={actualPoints} year={selectedYear} />
                    <ProgressGauge title="Hours" target={targetHours} actual={actualHours} year={selectedYear} />
                    <input type="hidden" data-testid="progress-widget-loaded" value={loaded.toString()} />
                </div>
            }
        </>
    )
}
