import { RouteComponentProps, withRouter } from 'react-router-dom';
import React from 'react';
import cupidFetch from '../modules/cupid-fetch';
import VerifyEventSessionResult from '../modules/data-objects/verify-event-session-result';
import Activity, { newActivity, activityFromJson } from '../modules/data-objects/activity';

class Props {
    eventSessionCode?: string | null;
}

class State {
    message: string = "Loading...";
}

class _AddOrEditCpd extends React.Component<Props & RouteComponentProps, State> {
    state: State = new State();

    async componentDidMount() {
        if (!this.props.eventSessionCode) {
            this.setState({ message: "Error: No event session code specified" });
            return;
        }
        const url = `/api/activities/verifyeventsession?eventsessioncode=${encodeURIComponent(this.props.eventSessionCode)}`
        const verifyEventSessionResult = await cupidFetch<null, VerifyEventSessionResult>(url, "GET");
        if (!verifyEventSessionResult) return;
        if (verifyEventSessionResult.existingActivity) {
            this.props.history.push(`/activities/${verifyEventSessionResult.existingActivity.activityId}/edit`);
        } else if (!verifyEventSessionResult.eventSession) {
            this.setState({ message: `Error: Event Session code ${this.props.eventSessionCode} not found` });
        } else {
            const activity = newActivity(null);
            const eventSession = activityFromJson(verifyEventSessionResult.eventSession)
            activity.activityDate = eventSession.activityDate;
            activity.eventSessionCode = eventSession.eventSessionCode;
            activity.title = eventSession.title;
            const savedActivity = await cupidFetch<Activity, Activity>(`/api/activities`, "POST", activity);
            if (!savedActivity) return null;
            this.props.history.push(`/activities/${savedActivity.activityId}/edit`);
        }
    }

    render() {
        return <>
            <div>{this.state.message}</div>
            <button onClick={() => this.props.history.push("/")}>Close</button>
        </>;
    }
}

const AddOrEditCpd = withRouter(_AddOrEditCpd);
export default AddOrEditCpd;
