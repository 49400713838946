import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect, useHistory } from "react-router-dom"
import LoginPage from '../authentication/login-page'
import ReviewerLogin from '../authentication/reviewer-login'
import cupidFetch from '../../modules/cupid-fetch'
import Layout from './layout'
import SysInfo, { getSysInfoGlobals } from '../../modules/data-objects/sys-info'
import Subject, { SubjectDto } from '../../modules/data-objects/subject'
import AlertBox from '../utils/alert-box'
import { unicode_copyright_sign, unicode_em_space, encodeCurrentUrl } from '../../modules/utils'
import * as H from 'history';
import { appConfigCpd } from './app-config-cpd'
import { appConfigCommittees } from './app-config-committees'
import { Helmet } from "react-helmet";

export const appVersion = "1.0.5"
export const iheemWebSiteUrl = "https://www.iheem.org.uk"

export const redirectToLoginPage = (history: H.History<unknown>) => (<Redirect to={`/login-page?returnurl=${encodeCurrentUrl(history)}`} />)

export const getReturnUrl = (history: H.History<unknown>) => new URLSearchParams(history.location.search).get("returnurl")

const getAppConfig = () => {
    const app = getSysInfoGlobals().sysInfo?.app
    switch (app) {
        case "CPD": return appConfigCpd;
        case "COMMITTEES": return appConfigCommittees;
        default: return null;
    }
}

export default function App() {
    const history = useHistory()
    const [subject, setSubject] = useState<Subject | null>(null)
    const [buildDate, setBuildDate] = useState("...")
    const [nonce, setNonce] = useState(0)

    const appConfig = getAppConfig();

    useEffect(
        () => {
            const getSubject = async () => {
                const p1 = cupidFetch<null, SysInfo>("/api/sysinfo")
                const p2 = cupidFetch<null, SubjectDto>("/api/session")
                const sysInfo = await p1
                if (!sysInfo) return
                const sessionResponse = await p2
                if (!sessionResponse) return
                getSysInfoGlobals().sysInfo = sysInfo
                setBuildDate(sysInfo.buildDate)
                setSubject(new Subject(sessionResponse))
            }
            getSubject()
        },
        [nonce]
    )

    const loginCallback = () => {
        setSubject(null)
        setNonce(x => x + 1)
        history.push(getReturnUrl(history) || "/")
    }

    const separator = `${unicode_em_space}|${unicode_em_space}`
    return (
        <>
            {appConfig && <Helmet>
                <title>{appConfig.appDescription}</title>
                <meta name="description" content={appConfig.appDescription} />
            </Helmet>
            }
            <div id="top" className={subject?.isSysadmin() ? "darkmode" : ""}>
                <div>
                    <AlertBox />
                    {
                        !subject
                            ?
                            <p>Loading Please Wait...</p>
                            :
                            <Switch>
                                <Route path="/login-page">
                                    {
                                        subject.isAuthenticated()
                                            ? <Redirect to={"/"} />
                                            : <LoginPage loginCallback={loginCallback} appDescription={appConfig!.appDescription} />
                                    }
                                </Route>
                                <Route path="/reviewer-login">
                                    <ReviewerLogin loginCallback={loginCallback} />
                                </Route>
                                {appConfig!.renderNonLayoutRoutes(subject, history)}
                                <Route>
                                    {
                                        !subject.isAuthenticated()
                                            ? redirectToLoginPage(history)
                                            : <Layout
                                                subject={subject}
                                                loginCallback={loginCallback}
                                                renderLayoutRoutes={appConfig!.renderLayoutRoutes}
                                                getNavEntries={appConfig!.getNavEntries} />
                                    }
                                </Route>
                            </Switch>
                    }
                    <div className="about">
                        <p>&nbsp;</p>
                        <span>Version {appVersion}</span>
                        <span>{separator}</span>
                        <span>Build date: {buildDate}</span>
                        <span>{separator}</span>
                        <span>Copyright {unicode_copyright_sign} Sales Now Ltd.</span>
                    </div>
                </div>
            </div>
        </>
    )
}
