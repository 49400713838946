import React, { ChangeEventHandler, FormEventHandler } from 'react';
import { showAlert } from '../../modules/show-alert';
import parseProblem from '../../modules/rfc7807-problem';

class Props {
    activityId!: string | null;
    uploadInProgressCallback!: (inProgress: boolean) => void;
    saveActivityCallback!: () => Promise<boolean>;
}

/*
 * if an upload is in progress, request is not null. note that
 * progress goes to 100% before the upload is complete so we can't
 * use that to determine whether it's still uploading, completed or
 * failed; we can only use that to show the progress bar.
 */
class State {
    file: string = "";
    lastSuccessfulFile: string = "";
    progress: number = 0;
    request: XMLHttpRequest | null = null;
}

class FileUpload extends React.Component<Props, State> {
    state: State = new State();
    formRef: React.RefObject<HTMLFormElement> = React.createRef();

    fileUploadSuccess = async () => {
        this.setState(state => ({ file: "", lastSuccessfulFile: extractFilename(state.file) }));
    }

    fileUploadError = () => {
        showAlert("Could not contact server - check network status and try again");
    }

    fileUploadComplete = (): void => {
        if (this.state.request!.status === 200) {
            this.fileUploadSuccess();
        } else {
            this.fileUploadFailed();
        }
        this.setState({ request: null })
        this.props.uploadInProgressCallback(false);
    }

    fileUploadFailed = () => {
        const request = this.state.request!;
        const problem = parseProblem(request.getResponseHeader("content-type"), request.responseText);
        const errorMessage = problem ?? "Could not contact server - check network status and try again";
        showAlert(errorMessage);
    }

    fileSelectorChange: ChangeEventHandler<HTMLInputElement> = async e => {
        const file = e.target.value;
        this.setState({ file, lastSuccessfulFile: "" });
        if (file) {
            if (!this.props.activityId) {
                const saveOk = await this.props.saveActivityCallback();
                if (!saveOk) return;
            }
            const formElement = this.formRef.current!;
            formElement.dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }

    fileUploadProgress = (e: ProgressEvent) => {
        this.setState({ progress: e.loaded / e.total });
    }

    performUpload = (formData: FormData) => {
        this.props.uploadInProgressCallback(true);
        this.setState({ request: new XMLHttpRequest() }, () => {
            const request = this.state.request!;
            request.onload = () => this.fileUploadComplete();
            request.onerror = () => { this.fileUploadError() };
            request.upload.onprogress = e => { this.fileUploadProgress(e) }
            request.open("POST", `/api/activities/${this.props.activityId}/attachments`);
            request.send(formData);
        });
    }

    cancelUpload = () => {
        this.state.request!.abort();
        this.setState({ progress: 0, request: null });
        this.props.uploadInProgressCallback(false);
    }

    onSubmit: FormEventHandler = e => {
        e.preventDefault();
        if (this.state.request) {
            this.cancelUpload();
        } else {
            const formData = new FormData(e.target as HTMLFormElement);
            this.performUpload(formData)
        }
    }

    render() {
        const showProgress = this.state.progress > 0 && this.state.progress < 1;
        const uploading = !!this.state.request;
        const caption = uploading ? "Cancel" : "Upload";
        return (
            <div className="small-12 cell">
                <form ref={this.formRef} onSubmit={this.onSubmit}>
                    <label htmlFor="fileUpload" className="button">Upload file</label>
                    <input
                        type="file"
                        id="fileUpload"
                        name="file"
                        className="show-for-sr"
                        disabled={uploading}
                        value={this.state.file}
                        onChange={this.fileSelectorChange}
                    />
                    {showProgress && <progress value={this.state.progress} max="1" />}
                    <span> </span>
                    {!this.state.request && extractFilename(this.state.file)}
                    <span> </span>
                    <input type="submit" className="button float-right" value={caption} disabled={!this.state.file} />
                    {!!this.state.lastSuccessfulFile &&
                        <div className="callout small success">
                            <p>file {this.state.lastSuccessfulFile} uploaded OK</p>
                            <button type="button" className="close-button" aria-label="Dismiss alert"
                                onClick={() => this.setState({ lastSuccessfulFile: "" })}>
                                <span aria-hidden="true">x</span>
                            </button>
                        </div>
                    }
                </form>
            </div>
        );
    }
}

// this code copied from https://html.spec.whatwg.org/multipage/input.html#file-upload-state-(type%3Dfile)
function extractFilename(path: string): string {
    if (path.substr(0, 12) === "C:\\fakepath\\")
        return path.substr(12); // modern browser
    var x;
    x = path.lastIndexOf('/');
    if (x >= 0) // Unix-based path
        return path.substr(x + 1);
    x = path.lastIndexOf('\\');
    if (x >= 0) // Windows-based path
        return path.substr(x + 1);
    return path; // just the file name
}

export default FileUpload;
