import ActivityType from "./activity-type";
import { getGlobal } from "../globals";

export default class SysInfo {
    app!: string
    buildDate!: string
    activityTypes!: ActivityType[]
    clientAppConfig!: ClientAppConfig
}

class ClientAppConfig {
    autoSaveInterval!: number
    activityList!: ActivityList
}

class ActivityList {
    startYear!: number
    endYear!: number
}

class SysInfoGlobals {
    // we're defaulting sysInfo to null to make sure you don't access it until it's been set properly
    sysInfo: SysInfo = null!
}

const globalName = "sysInfo";

export function getSysInfoGlobals(): SysInfoGlobals {
    return getGlobal<SysInfoGlobals>(SysInfoGlobals, globalName);
}

export function getSysInfo(): SysInfo {
    return getSysInfoGlobals().sysInfo
}
