import React, { useState, useEffect } from 'react'
import ActivityNote, { activityNoteFromJson } from '../../modules/data-objects/activity-note'
import Subject from '../../modules/data-objects/subject'
import cupidFetch from '../../modules/cupid-fetch'
import { showConfirm } from '../../modules/show-alert'
import { formatDateTimeForDisplay } from '../../modules/data-objects/date-utils'
import TextareaAutosize from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

class Props {
    activityId!: string
    activityNote!: ActivityNote | null
    subject!: Subject
    dirtyNoteCallback!: (dirty: boolean) => void
}

export default function NoteDetails({ activityId, activityNote, subject, dirtyNoteCallback }: Props) {
    const [editMode, setEditMode] = useState(!activityNote)
    const [noteDate, setNoteDate] = useState(activityNote?.noteDate)
    const [noteText, setNoteText] = useState(activityNote?.noteText ?? "")
    const [waitingForServer, setWaitingForServer] = useState(false)
    const [activityNoteId, setActivityNoteId] = useState(activityNote?.activityNoteId)
    const dirty = (editMode && !!(activityNoteId || noteText))
    const reviewerEmail = activityNote?.reviewerEmail ?? subject.authenticated!.emailAddress
    const reviewerName = activityNote?.reviewerName ?? subject.authenticated!.displayName
    const editButtonVisible = !editMode && subject.isReviewer()
    const acceptButtonVisible = editMode && subject.isReviewer()
    const deleteButtonVisible = subject.isReviewer()
    const acceptButtonEnabled = acceptButtonVisible && noteText

    useEffect(
        () => {
            dirtyNoteCallback(dirty)
        },
        [dirty, dirtyNoteCallback]
    )

    const noteTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => setNoteText(event.target.value)

    const getUrl = () => {
        const suffix = activityNoteId ? `/${activityNoteId}` : ""
        return `/api/activities/${activityId}/notes${suffix}`
    }

    const onAcceptClick = async () => {
        const method = activityNoteId ? "PATCH" : "POST"
        const payload: ActivityNote = { ...new ActivityNote(), noteText }
        setWaitingForServer(true)
        const result = await cupidFetch<ActivityNote, ActivityNote>(getUrl(), method, payload)
        setWaitingForServer(false)
        if (!result) return
        const newActivityNote = activityNoteFromJson(result)
        setActivityNoteId(newActivityNote.activityNoteId)
        setNoteDate(newActivityNote.noteDate)
        setEditMode(false)
    }

    const onDeleteClick = async () => {
        if (activityNoteId || noteText) {
            if (!await showConfirm("Delete this note?")) return
        }
        if (activityNoteId) {
            setWaitingForServer(true)
            const result = await cupidFetch<ActivityNote, null>(getUrl(), "DELETE")
            setWaitingForServer(false)
            if (!result) return
        }
        setNoteText("")
        setNoteDate(null)
        setActivityNoteId(null)
        setEditMode(true)
    }

    const onEditClick = () => {
        setEditMode(true)
    }

    return (
        <fieldset disabled={waitingForServer}>
            <div className="callout warning">
                <h5><strong>Note from:</strong> {reviewerName} ({reviewerEmail}) {formatDateTimeForDisplay(noteDate)}</h5>
                {editMode
                    ? <TextareaAutosize
                        value={noteText}
                        maxLength={1048576}
                        placeholder="Enter a note and click save"
                        onChange={noteTextChange} />
                    : <p className="textblock">{noteText}</p>
                }
            </div>
            <div className="small button-group">
                {editButtonVisible && <button className="warning button" onClick={onEditClick}>
                    <FontAwesomeIcon icon={faPen} inverse /> Edit</button>}
                {acceptButtonVisible && <button className="success button" disabled={!acceptButtonEnabled} onClick={onAcceptClick}>
                    <FontAwesomeIcon icon={faCheckCircle} inverse /> Save</button>}
                {deleteButtonVisible && <button className="alert button" onClick={onDeleteClick}>
                    <FontAwesomeIcon icon={faTrash} /> Delete</button>}
            </div>
        </fieldset>
    )
}
