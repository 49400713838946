import React from 'react';
import { getAlertGlobals } from '../../modules/show-alert';

class State {
    message: string | null = null;
    isConfirm: boolean | null = null;
}

type Resolver = (value: any) => void;

// because alerts are async, there can be any number of them present at any one time.
// we queue them up and only show the second one once the first has been dismissed.
class QueueEntry {
    message: string;
    resolver: Resolver;
    isConfirm: boolean;
    promise: Promise<any>;
    constructor(message: string, isConfirm: boolean, promise: Promise<any>, resolver: Resolver) {
        this.message = message;
        this.isConfirm = isConfirm;
        this.promise = promise;
        this.resolver = resolver;
    }
}

class AlertBox extends React.Component<{}, State> {
    state: State = new State();
    queue: QueueEntry[] = [];

    constructor(props: {}) {
        super(props);
        const globals = getAlertGlobals();
        globals.showAlert = this.showAlert.bind(this);
        globals.showConfirm = this.showConfirm.bind(this);
    }

    // We don't want to queue up loads of identical messages unless they are confirms,
    // so when we come to add a new one, if it's not a confirm and there's already an identical
    // one in the queue (which includes being already displayed), we just return the same promise
    // so it will resolve when the user accepts the first one.
    addAlertToQueue(message: string, isConfirm: boolean): Promise<any> {
        if (!isConfirm) {
            const queueEntry = this.queue.find(o => o.message === message && !o.isConfirm);
            if (queueEntry != null) {
                return queueEntry.promise!;
            }
        }
        let resolver: Resolver;
        const promise = new Promise((r: Resolver) => {
            resolver = r;
        });
        // we're guaranteed resolver is set now, even though the compiler doesn't know that
        this.queue.push(new QueueEntry(message, isConfirm, promise, resolver!));
        if (this.queue.length === 1) {
            this.setState({ message, isConfirm });
        }
        return promise;
    }

    showAlert(message: string): Promise<void> {
        return this.addAlertToQueue(message, false);
    }

    showConfirm(message: string): Promise<boolean> {
        return this.addAlertToQueue(message, true);
    }

    buttonClicked(result: boolean) {
        if (this.queue.length < 1) {
            throw new Error("alert button clicked when no alert shown");
        }
        this.queue.shift()!.resolver(result);
        // show the next message or, if the queue is empty, nothing
        if (this.queue.length) {
            const { message, isConfirm } = this.queue[0];
            this.setState({ message, isConfirm });
        } else {
            this.setState({ message: null, isConfirm: null });
        }
    }

    render() {
        return (
            this.state.message != null
                ?
                <div className="alertbox">
                    <div className="alertboxcontent">
                        <p>{this.state.message}</p>
                        <div className="button-group small float-right">
                            <button className="button primary" onClick={() => this.buttonClicked(true)}>OK</button>
                            {this.state.isConfirm &&
                                <button className="button secondary" onClick={() => this.buttonClicked(false)}>Cancel</button>
                            }
                        </div>
                    </div>
                </div>
                :
                null
        );
    }
}

export default AlertBox
