import React from 'react'

class Props {
    value!: string
    onChange!: (value: string) => void
    form?: string
    id?: string
    placeholder?: string
}

export function DateInput({ value, onChange, form, placeholder, id }: Props) {

    function dateOnChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.validity.valid) {
            onChange(event.target.value!);
        }
    }

    return <input type="date"
        id={id}
        form={form}
        placeholder={placeholder}
        value={value}
        required={true}
        min="1900-01-01"
        max="2099-12-31"
        onChange={dateOnChange}
    />
}
