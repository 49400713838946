import React from 'react';
import { useHistory } from 'react-router-dom'
import Activity, { activityFromJson } from '../../modules/data-objects/activity';
import { text_expected_learning, text_actual_learning, text_impact, text_comments, encodeCurrentUrl } from '../../modules/utils';
import AttachmentLists from '../edit-cpd/attachment-lists';
import { formatDateWithoutTimeForDisplay } from '../../modules/date-without-time';
import { getSysInfo } from '../../modules/data-objects/sys-info';
import ActivityNoteList from './activity-note-list';
import Subject from '../../modules/data-objects/subject';
import { showConfirm } from '../../modules/show-alert';
import cupidFetch from '../../modules/cupid-fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons'

class Props {
    activity!: Activity
    subject!: Subject
    dirtyNoteCallback!: (dirty: boolean) => void
    reloadActivityList!: () => void
}

export default function ActivityDetails({ activity, dirtyNoteCallback, subject, reloadActivityList }: Props) {
    const history = useHistory()

    const activityType = activity.activityTypeId
        ? getSysInfo().activityTypes.find(o => o.activityTypeId === activity.activityTypeId)?.title
        : "n/a"

    const editButtonClick = () => {
        const returnUrl = encodeCurrentUrl(history)
        history.push(`/activities/${activity.activityId}/edit?returnurl=${returnUrl}`)
    }

    const deleteButtonClick = async () => {
        if (!await showConfirm(`Delete this activity "${activity.title}"`)) return
        const result = await cupidFetch(`/api/activities/${activity.activityId}`, "DELETE")
        if (!result) return
        reloadActivityList()
    }

    const completeButtonClick = async () => {
        if (!await showConfirm(`Complete this activity "${activity.title}"`)) return
        const result = await cupidFetch<null, Activity>(`/api/activities/${activity.activityId}`)
        if (!result) return
        const fetchedActivity = activityFromJson(result)
        fetchedActivity.isCompleted = true
        const result2 = await cupidFetch<Activity, Activity>(`/api/activities/${fetchedActivity.activityId}`, "PATCH", fetchedActivity)
        if (!result2) return
        reloadActivityList()
    }

    return (
        <div>
            <div className="grid-x">
                <div className="cell medium-4  ">
                    <h2>{activity.title}</h2>
                    {!subject.isReviewer() &&
                        <div className="small button-group">
                            <button onClick={editButtonClick} className="warning button"><FontAwesomeIcon icon={faPen} inverse /> Edit</button>
                            {!activity.isCompleted && <>
                                <button className="button" onClick={completeButtonClick}><FontAwesomeIcon icon={faCheckCircle} /> Complete</button>
                                <button className="alert button" onClick={deleteButtonClick}><FontAwesomeIcon icon={faTimesCircle} /> Delete</button>
                            </>}
                        </div>
                    }
                    <div className="callout secondary">
                        <p><strong>Activity type:</strong> {activityType} </p>
                        <p><strong>Date:</strong> {formatDateWithoutTimeForDisplay(activity.activityDate)}</p>
                        {activity.eventSessionCode && <p><strong>Event session code:</strong> {activity.eventSessionCode}</p>}
                        {activity.points && <p><strong>Points:</strong>  {activity.points}</p>}
                        {activity.hours && <p><strong>Hours:</strong> {activity.hours} </p>}
                        {activity.competencyRef && <p><strong>Competency ref:</strong> {activity.competencyRef}</p>}
                    </div>
                    <AttachmentLists
                        activity={activity}
                        attachmentListNonce={0}
                    />
                </div>
                <div className="cell medium-7 medium-offset-1">
                    {activity.expectedLearning && <>
                        <h5>{text_expected_learning}</h5>
                        <p className="textblock">{activity.expectedLearning}</p>
                    </>}
                    {activity.actualLearning && <>
                        <h5>{text_actual_learning}</h5>
                        <p className="textblock">{activity.actualLearning}</p>
                    </>}
                    {activity.impact && <>
                        <h5>{text_impact}</h5>
                        <p className="textblock">{activity.impact}</p>
                    </>}
                    {activity.comments && <>
                        <h5>{text_comments}</h5>
                        <p className="textblock">{activity.comments}</p>
                    </>}
                    <ActivityNoteList activity={activity} subject={subject} dirtyNoteCallback={dirtyNoteCallback} />
                </div>
            </div>
        </div>
    );
}
