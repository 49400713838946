import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from "lodash";
import { getSysInfo } from '../../modules/data-objects/sys-info';
import getCurrentDate from '../../modules/current-date';
import ActivityList from './activity-list';
import { startOfYear, endOfYear } from '../../modules/date-without-time';
import { encodeCurrentUrl } from '../../modules/utils';
import ProgressWidget from '../target-progress/progress-widget';
import Subject from '../../modules/data-objects/subject';
import { LoggedInAs } from '../utils/logged-in-as';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

class YearSelectorEntry {
    title!: string
    queryString!: string
    year!: number | null
}

class Props {
    subject!: Subject
}

const queryStringFromDateRange = (startDate: string, endDate: string) =>
    `?startdate=${startDate}&enddate=${endDate}`

const queryStringFromYearRange = (startYear: number, endYear: number) =>
    queryStringFromDateRange(startOfYear(startYear), endOfYear(endYear))

const queryStringFromYear = (year: number) => queryStringFromYearRange(year, year)

const getCurrentRange = (startDate: string | null, endDate: string | null): string =>
    (startDate || endDate)
        ? queryStringFromDateRange(startDate!, endDate!)
        : queryStringFromYear(getCurrentDate().getFullYear())

function getYearSelectorValues(selectedRange: string): YearSelectorEntry[] {
    const activityListConfig = getSysInfo().clientAppConfig.activityList
    const yearRange: YearSelectorEntry[] = _.range(activityListConfig.startYear, activityListConfig.endYear + 1)
        .map(year => ({
            title: year.toString(),
            queryString: queryStringFromYear(year),
            year
        }))
    yearRange.push({
        title: "Other",
        queryString: queryStringFromYearRange(activityListConfig.endYear + 1, activityListConfig.startYear - 1),
        year: null
    })
    if (!yearRange.some(o => o.queryString === selectedRange)) {
        yearRange.push({
            title: "Custom",
            queryString: "Custom",
            year: null
        })
    }
    return yearRange
}

function ActivityListPage({ subject }: Props) {
    const history = useHistory()
    const location = useLocation()
    const [nonce, setNonce] = useState(0)

    function yearOnChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const value = event.target.value;
        history.push(`/activities${value}`);
    }

    const newButtonClick = () => {
        const returnUrl = encodeCurrentUrl(history);
        history.push(`/activities/new?returnurl=${returnUrl}`);
    }

    const reloadActivityList = () => {
        setNonce(oldValue => oldValue + 1)
    }

    const searchParams = new URLSearchParams(location.search)
    const startDateString = searchParams.get("startdate")
    const endDateString = searchParams.get("enddate")
    const selectedRange = getCurrentRange(startDateString, endDateString)
    const yearSelectorValues = getYearSelectorValues(selectedRange)
    const selectedEntry = yearSelectorValues.find(o => o.queryString === selectedRange) ?? yearSelectorValues[yearSelectorValues.length - 1]

    return (
        <>
            <div className="grid-container main-content">
                <div className="grid-x">
                    <div className="cell medium-12">
                        <h1 className="page-title">My CPD</h1>
                        <LoggedInAs subject={subject} />
                    </div>
                </div>
                <div className="grid-x">
                    <div className="cell medium-7 intro-text">
                        <p>Below is your full MyIHEEM CPD record (italics represents CPD in draft status). Click on an activity to expand the details.</p>
                        <p>To add a new piece of CPD activity, click on the New CPD button.</p>
                        <p>The gauges below show your actual points and hours to date against targeted for the selected calendar year.</p>
                        {selectedEntry.year &&
                            <ProgressWidget key={nonce} subject={subject} year={selectedEntry.year} />
                        }
                        <div className="grid-x grid-padding-x">
                            <div className="small-3 cell">
                                <label htmlFor="year" className="text-right middle calendar">Calendar year</label>
                            </div>
                            <div className="small-3 cell">
                                <select id="year"
                                    value={selectedEntry.queryString}
                                    onChange={yearOnChange}>
                                    {yearSelectorValues.map(o =>
                                        <option key={o.title} value={o.queryString} disabled={o.title === "Custom"}>{o.title}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="cell medium-4 medium-offset-1">
                        <button className="button" onClick={newButtonClick}><FontAwesomeIcon icon={faPlusCircle} size="lg" /> New CPD</button>
                    </div>
                </div>
            </div>

            <ActivityList
                key={selectedRange}
                queryString={selectedRange}
                reloadActivityList={reloadActivityList}
                subject={subject}
            />
        </>
    )
}

export default ActivityListPage;
