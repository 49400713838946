import React from 'react';
import AttachmentList from './attachment-list';
import Activity from '../../modules/data-objects/activity';
import cupidFetch from '../../modules/cupid-fetch';
import VerifyEventSessionResult from '../../modules/data-objects/verify-event-session-result';

class Props {
    activity!: Activity;
    attachmentListNonce!: number;
    anyAttachmentsCallback?: (anyAttachments: boolean) => void;
    enableDelete?: boolean;
}

class State {
    eventSessionActivityId: string | null = null;
}

class AttachmentLists extends React.Component<Props, State> {
    state: State = new State()
    mounted: boolean = false

    componentDidMount() {
        this.mounted = true;
        this.getEventSessionActivityId()
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    async getEventSessionActivityId() {
        if (!this.props.activity.isEventSession && this.props.activity.eventSessionCode) {
            const url = `/api/activities/verifyeventsession?eventsessioncode=${encodeURIComponent(this.props.activity.eventSessionCode)}`;
            const response = await cupidFetch<null, VerifyEventSessionResult>(url);
            if (!this.mounted) return;
            this.setState({ eventSessionActivityId: response?.eventSession?.activityId! })
        } else {
            this.setState({ eventSessionActivityId: null })
        }
    }

    async componentDidUpdate(prevProps: Props) {
        if (prevProps.activity.eventSessionCode !== this.props.activity.eventSessionCode) {
            this.getEventSessionActivityId()
        }
    }

    render() {
        return (
            <>
                <AttachmentList
                    activityId={this.state.eventSessionActivityId}
                    title="Session Resources"
                    key={this.state.eventSessionActivityId ?? ""}
                />
                <p>&nbsp;</p>
                <AttachmentList
                    activityId={this.props.activity.activityId}
                    title="Attachments"
                    key={`${this.props.attachmentListNonce}::${this.props.activity.activityId}`}
                    anyAttachmentsCallback={this.props.anyAttachmentsCallback}
                    enableDelete={this.props.enableDelete}
                />
            </>
        );
    }
}

export default AttachmentLists;
