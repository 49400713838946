import React from 'react';
import cupidFetch from "../../modules/cupid-fetch";
import Attachment from '../../modules/data-objects/attachment';
import { showConfirm } from '../../modules/show-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

class Props {
    title!: string;
    activityId!: string | null;
    anyAttachmentsCallback?: (anyAttachments: boolean) => void;
    enableDelete?: boolean
}

class State {
    attachments: Attachment[] = [];
}

class AttachmentList extends React.Component<Props, State> {
    mounted: boolean = false;
    state: State = new State();

    anyAttachmentsCallback(anyAttachments: boolean) {
        if (this.props.anyAttachmentsCallback) {
            this.props.anyAttachmentsCallback(anyAttachments)
        }
    }

    async refreshAttachmentList() {
        if (!this.props.activityId) {
            this.setState({ attachments: [] });
            this.anyAttachmentsCallback(false);
            return
        }
        if (!this.mounted) return;
        const attachments = await cupidFetch<null, Attachment[]>(
            `/api/activities/${this.props.activityId}/attachments`);
        if (!this.mounted) return;
        if (attachments) {
            this.setState({ attachments });
            this.anyAttachmentsCallback(!!attachments.length)
        }
    }

    async componentDidMount() {
        this.mounted = true;
        await this.refreshAttachmentList();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onDeleteClick = async (event: React.MouseEvent<HTMLAnchorElement>, attachmentId: string) => {
        event.preventDefault();
        const attachment = this.state.attachments.find(o => o.attachmentId === attachmentId)!
        if (!await showConfirm(`Delete this attachment "${attachment.fileName}"?`)) {
            return;
        }
        const result = await cupidFetch(
            `/api/activities/${attachment.activityId}/attachments/${attachment.attachmentId}`,
            "DELETE")
        if (!result) return
        await this.refreshAttachmentList()
    }

    render() {
        if (!this.state.attachments.length) {
            return null;
        }
        return (
            <>
                <h5>{this.props.title}</h5>
                <ul className="resources-list">
                    {this.state.attachments.map(attachment =>
                        <li key={attachment.attachmentId ?? ""}>
                            <a href={`/api/activities/${attachment.activityId}/attachments/${attachment.attachmentId}`}
                                target="_blank"
                                rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faCloudDownloadAlt} />
                                <span> </span>
                                {reformatFileName(attachment.fileName)}
                            </a>
                            {
                                this.props.enableDelete &&
                                <a href="#/" data-testid={`delete-attachment-${attachment.fileName}`} onClick={(e) => this.onDeleteClick(e, attachment.attachmentId!)}>
                                    <span> </span>
                                    <FontAwesomeIcon icon={faTrash} />
                                </a>
                            }
                        </li>
                    )}
                </ul>
            </>
        );
    }
}

export function reformatFileName(s?: string | null): string {
    if (!s) return ""
    const i = s.lastIndexOf(".")
    return i === -1
        ? s
        : `${s.substr(0, i)} (${s.substr(i + 1).toLowerCase()})`
}

export default AttachmentList;
