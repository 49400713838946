import React from 'react';
import Activity from '../../modules/data-objects/activity';
import { nanToNull, text_expected_learning, text_actual_learning, text_impact, text_comments } from '../../modules/utils';
import { getSysInfo } from '../../modules/data-objects/sys-info';
import { DateInput } from '../utils/date-input';
import TextareaAutosize from 'react-textarea-autosize';

class Props {
    activity!: Activity
    updateFieldCallback!: (updates: Partial<Activity>) => void;
}

class MainInputFields extends React.Component<Props, {}> {

    fieldOnChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>) => {
        // we can't declare updates as Partial<Activity> because we're assigning properties dynamically
        const updates: any = {}
        updates[event.target.id] = event.target.value
        this.props.updateFieldCallback(updates);
    }

    pointsOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.validity.valid) {
            this.props.updateFieldCallback({ points: nanToNull(parseInt(event.target.value)) });
        }
    }

    hoursOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.validity.valid) {
            this.props.updateFieldCallback({ hours: nanToNull(parseFloat(event.target.value)) });
        }
    }

    activityDateOnChange = (value: string) => this.props.updateFieldCallback({ activityDate: value })

    render() {
        const activityTypes = [{ activityTypeId: "", title: "" }, ...getSysInfo().activityTypes]
        return (
            <>
                <div className="small-12 cell">
                    <label htmlFor="title">Title</label>
                    <input type="text" id="title" value={this.props.activity.title} maxLength={256} onChange={this.fieldOnChange} />
                </div>
                <div className="small-12 cell">
                    <label htmlFor="date">Date</label>
                    <DateInput id="date" value={this.props.activity.activityDate!} onChange={this.activityDateOnChange} />
                </div>
                <div className="small-12 cell">
                    <label htmlFor="activityTypeId">Activity type</label>
                    <select id="activityTypeId"
                        value={this.props.activity.activityTypeId ?? ""}
                        onChange={this.fieldOnChange}>
                        {
                            activityTypes.map(o =>
                                <option key={o.activityTypeId} value={o.activityTypeId}>{o.title}</option>
                            )
                        }
                    </select>
                </div>
                <div className="small-12 cell">
                    <label htmlFor="points">Points</label>
                    <input type="number" step="1" min="0" max={1000000} id="points" value={this.props.activity.points ?? ""} onChange={this.pointsOnChange} />
                </div>
                <div className="small-12 cell">
                    <label htmlFor="hours">Hours</label>
                    <input type="number" step="0.5" min="0" max={1000000} id="hours" value={this.props.activity.hours ?? ""} onChange={this.hoursOnChange} />
                </div>
                {!this.props.activity.isEventSession &&
                    <>
                        <div className="small-12 cell">
                            <label htmlFor="competencyRef">Competency reference</label>
                            <input type="text" id="competencyRef" value={this.props.activity.competencyRef} maxLength={256} onChange={this.fieldOnChange} />
                        </div>
                        <div className="small-12 cell">
                            <label htmlFor="expectedLearning">{text_expected_learning}</label>
                            <TextareaAutosize id="expectedLearning"
                                maxLength={1048576}
                                value={this.props.activity.expectedLearning}
                                onChange={this.fieldOnChange} />
                        </div>
                        <div className="small-12 cell">
                            <label htmlFor="actualLearning">{text_actual_learning}</label>
                            <TextareaAutosize id="actualLearning"
                                value={this.props.activity.actualLearning}
                                maxLength={1048576}
                                onChange={this.fieldOnChange} />
                        </div>
                        <div className="small-12 cell">
                            <label htmlFor="impact">{text_impact}</label>
                            <TextareaAutosize id="impact"
                                value={this.props.activity.impact}
                                maxLength={1048576}
                                onChange={this.fieldOnChange} />
                        </div>
                        <div className="small-12 cell">
                            <label htmlFor="comments">{text_comments} (optional)</label>
                            <TextareaAutosize id="comments"
                                value={this.props.activity.comments}
                                maxLength={1048576}
                                onChange={this.fieldOnChange} />
                        </div>
                    </>
                }
            </>
        );
    }
}

export default MainInputFields;
