import React from 'react'
import Activity from '../../modules/data-objects/activity'
import ActivityListRow from './activity-list-row'
import Subject from '../../modules/data-objects/subject'
import { Accordion, AccordionItem, AccordionTitle } from 'react-foundation'

class Props {
    activities: Activity[] | null = null
    reloadActivityList!: () => void
    subject!: Subject
}

const ActivityListView = ({ activities, reloadActivityList, subject }: Props) =>
    <div className="grid-container">
        <Accordion>
            {!activities ?
                <AccordionItem>
                    <AccordionTitle>Loading...</AccordionTitle>
                </AccordionItem>
                : activities.length === 0 ?
                    <AccordionItem>
                        <AccordionTitle>No activities</AccordionTitle>
                    </AccordionItem>
                    :
                    activities.map(o =>
                        <ActivityListRow
                            key={o.activityId!}
                            reloadActivityList={reloadActivityList}
                            activity={o}
                            subject={subject} />
                    )
            }
        </Accordion>
    </div>

export default ActivityListView
