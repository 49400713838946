import Subject from "../../modules/data-objects/subject";
import { Route, Switch } from "react-router-dom";
import React from "react";
import EditCpd from "../edit-cpd/edit-cpd";
import AddOrEditCpd from "../add-or-edit-cpd";
import ReviewerActivityList from "../activity-list/reviewer-activity-list";
import ActivityListPage from "../activity-list/activity-list-page";
import ReviewGrantPage from "../review-grant/review-grant-page";
import Targets from "../targets";
import UserDetails from "../user-details";
import Home from "./home";
import { redirectToLoginPage, getReturnUrl } from "./app";
import { AppConfig } from "./app-config";
import * as H from 'history';

export const appConfigCpd : AppConfig = {
    appDescription: "MyIHEEM CPD",
    renderNonLayoutRoutes: (subject:Subject, history:H.History<unknown>) => [
        <Route key={1} path={["/activities/:id/edit", "/activities/new"]} render={routeProps =>
            !subject.isAuthenticated()
                ? redirectToLoginPage(history)
                : <EditCpd
                    subject={subject!}
                    activityId={routeProps.match.params["id"]}
                    returnUrl={getReturnUrl(history)}
                />
        } />,
        <Route key={2} path={"/activities/addoredit"} render={routeProps =>
            !subject.isAuthenticated()
                ? redirectToLoginPage(history)
                : <AddOrEditCpd eventSessionCode={new URLSearchParams(routeProps.location.search).get("eventsessioncode")} />
        } />
    ],
    renderLayoutRoutes: (subject:Subject) =>
        subject.isReviewer()
            ? <Route>
                <ReviewerActivityList subject={subject} />
            </Route>
            : <Switch>
                <Route path="/activities">
                    <ActivityListPage subject={subject} />
                </Route>
                <Route path="/reviewgrants">
                    <ReviewGrantPage subject={subject} />
                </Route>
                <Route path="/targets">
                    <Targets subject={subject} />
                </Route>
                <Route path="/user-details">
                    <UserDetails subject={subject} />
                </Route>
                <Route path="/">
                    <Home subject={subject} />
                </Route>
            </Switch>
    ,
    getNavEntries: (subject:Subject) => subject.isReviewer()
    ? []
    : [
        { url: "/", text: "Home" },
        { url: "/activities", text: "My CPD" },
        { url: "/reviewgrants", text: "Grant reviewer access" },
        { url: "/targets", text: "Set targets" },
        { url: "/user-details", text: "Update user details" },
    ]
}
