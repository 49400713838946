import _ from "lodash";
import { getCurrentDateWithoutTime } from "../date-without-time";

export default class Activity {
    activityId: string | null = null;
    userId: string | null = null;
    activityTypeId: string | null = null;
    title: string = "";
    activityDate: string | null = null;
    isEventSession: boolean = false;
    eventSessionCode: string = "";
    competencyRef: string = "";
    isCompleted: boolean = false;
    points: number | null = null;
    hours: number | null = null;
    expectedLearning: string = "";
    actualLearning: string = "";
    impact: string = "";
    comments: string = "";
    hasNotes: boolean = false;
}

/**
 * santitizes an Activity parsed from JSON. the parameter isn't really an activity object,
 * it's a JSON object with the same properties as an activity object!
 * @param activity 
 */
export function activityFromJson(activity: Activity) : Activity {
    return {
        ...new Activity(),
        ...activity,
        title: activity.title ?? "",
        eventSessionCode: activity.eventSessionCode ?? "",
        competencyRef: activity.competencyRef ?? "",
        points: activity.points ?? null,
        hours: activity.hours ?? null,
        expectedLearning: activity.expectedLearning ?? "",
        actualLearning: activity.actualLearning ?? "",
        impact: activity.impact ?? "",
        comments: activity.comments ?? "",
        activityDate: activity.activityDate ?? null,
    }
}

export function newActivity(activityId: string | null): Activity {
    return {
        ...new Activity(),
        activityId,
        activityDate: getCurrentDateWithoutTime()
    }
}

export function activityIsEmpty(activity: Activity) {
    return !(activity.title || activity.eventSessionCode);
}

export function activityEquals(activity1: Activity, activity2: Activity, ignoreIsCompleted: boolean): boolean {
    return !_.isEqual(
        activity1,
        { ...activity2, isCompleted: ignoreIsCompleted ? activity1.isCompleted : activity2.isCompleted }
    );
}
